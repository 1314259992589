<template>
  <div>
    <v-dialog
      v-model="dialog"
      width="850"
      :persistent="load"
      scrollable>
      <v-card>
        <v-card-title class="">
          {{ !editar ? 'Códigos de Barra' : 'Editar/Agregar Códigos de Barra' }}
          <v-spacer></v-spacer>
          <template>
            <v-switch
              v-if="datos.permiso == 1"
              v-model="editar"
              label="Editar"
              class="d-flex align-center py-0 my-0"
              hide-details
              dense
            ></v-switch><!--&& articulo_copy.inhabilitado != 1-->
            <v-divider
              class="mx-3"
              vertical
            ></v-divider>
          </template>
          <v-btn icon @click="dialog = false">
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <div v-if="editar" class="body-1 px-3 pt-3 pb-0">
          <v-form @submit.prevent="agregarNvoCodigosBarra" ref="formCodigosBarra" class="pt-2">
            <v-row class="pa-0">
              <v-col cols="2">
                <v-text-field
                  v-model.trim="datos.codigo"
                  label="Art. Código"
                  :readonly="true"
                  :filled="true"
                  validate-on-blur
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-autocomplete
                  v-model="datosAgregar.proveedor"
                  label="Proveedor"
                  item-text="nombre"
                  item-value="codigo"
                  :rules="editar ? [rules.required] : []"
                  :items="datos.proveedores"
                  validate-on-blur
                  outlined
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model.trim="datosAgregar.codigoBarra"
                  label="Código de Barra"
                  validate-on-blur
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model.trim="datosAgregar.descripcion"
                  label="Descripción"
                  validate-on-blur
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="1">
                <v-btn
                  color="success"
                  small
                  fab
                  class="mt-n1"
                  title="Agregar Nuevo"
                  type="submit">
                  <v-icon>
                    fas fa-check
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </div>
        <v-spacer></v-spacer>
        <v-card-text class="pa-3">
          <v-data-table
            class="cebra elevation-2 mt-2"
            :search="search"
            :headers="headers"
            :items="datosPropsCopia.codigosBarra"
            :loading="load"
            :items-per-page="-1"
            hide-default-footer
            dense
            >
            <template v-slot:top>
              <v-row class="d-flex justify-end pa-2" no-gutters>
                <v-col cols="6" sm="3" xl="2">
                  <SearchDataTableVue
                    v-model="search"
                  />
                </v-col>
              </v-row>
            </template>
            <template v-if="editar" v-slot:[`item.codigo_barra_prov`]="{ item }">
              <v-text-field
                v-model="item.codigo_barra_prov"
                hide-details
                dense
                outlined
                class="d-inline-flex"
                @change="detectarCambios(item)"
              ></v-text-field>
            </template>
            <template v-if="editar" v-slot:[`item.prov_articulo_descripcion`]="{ item }">
              <v-text-field
                v-model="item.prov_articulo_descripcion"
                type="text"
                hide-details
                dense
                outlined
                class="d-inline-flex"
              ></v-text-field>
            </template>
            <template v-slot:[`item.accion`]="{item}">
              <v-btn
                :disabled="!editar"
                icon color="error" title="Eliminar"
                @click="eliminarCodigoBarra(item)">
                <v-icon>
                  fas fa-times-circle
                </v-icon>
              </v-btn>
            </template>
            <template v-slot:no-data>
              <v-alert
                class="mx-auto mt-4"
                max-width="400"
                type="warning"
                border="left"
                dense
                text
              >
                No hay datos para los filtros seleccionados
              </v-alert>
            </template>
          </v-data-table>
          
        </v-card-text>

        <v-card-actions class="d-flex justify-end pb-4">
          <v-btn
            class="mr-2"
            :color="editar ? 'error' : 'secondary'"
            :disabled="load"
            @click="dialog = false"
          >
            {{ editar ? 'Cancelar' : 'Volver' }}
          </v-btn>
          <BtnConfirmar
            v-if="editar"
            :loading="load"
            @action="guardar()"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>  
  </div>
</template>

<script>
import { order_list_by } from '../../util/utils'
import BtnConfirmar from '../util/BtnConfirmar'
import SearchDataTableVue from '../../components/util/SearchDataTable.vue'

export default {
  name: 'ModalCodigosBarra',
  props:{
    value: Boolean,
    datos:{
      type: Object,
      default:{
        codigosBarra: [],
        proveedores: [],
        codigo: null,
        //nombre: '',
        permiso: null
      }
    }
  },
  data(){
    return{
      load: false,
      editar: false,
      datosPropsCopia: {},
      search: '',
      headers: [
        { text: 'Código', value: 'articulo_codigo', sortable: false},
        { text: 'Proveedor', value: 'proveedor_nombre', sortable: true},
        { text: 'Código de Barra', value: 'codigo_barra_prov',sortable: false},
        { text: 'Descripción Proveedor', value: 'prov_articulo_descripcion', sortable: false},
        { text: 'Acciones',value: 'accion', align: 'center', sortable: false, filterable: false}
      ],
      //datosAgregar
      datosAgregar:{
        proveedor: null,
        codigoBarra: null,
        descripcion: null,
      },        //si existe, muestra mensaje
      //initABMArticulos
      proveedores: [],
      //Reglas
      rules:{
        required: value => !!value || 'Campo requerido'
      }
    }
  },
  async created(){
  },
  methods:{
    agregarNvoCodigosBarra(){
      if(this.$refs.formCodigosBarra.validate()){
        //si no existe el proveedor, agrega:
        if(this.datosPropsCopia.codigosBarra.map(elem => elem.proveedor_codigo).indexOf(this.datosAgregar.proveedor) === -1){
          this.datosPropsCopia.codigosBarra.push({
            "codigo_barra_prov": this.datosAgregar.codigoBarra,
            "codigo_ean": null,
            "modificado": 0,
            "prov_articulo_id": 0,
            "articulo_codigo": this.datosPropsCopia.codigo,
            "proveedor_codigo": this.datosAgregar.proveedor,
            "proveedor_nombre": (this.datos.proveedores.find(elem => elem.codigo === this.datosAgregar.proveedor)).nombre,
            "prov_articulo_descripcion": this.datosAgregar.descripcion,
          });
        }else{ //si el proveedor aparece en la lista, controla que no se repita el código de barra
          //si no está ese código de barra, agrega
          if(this.datosPropsCopia.codigosBarra.map(elem => elem.codigo_barra_prov).indexOf(this.datosAgregar.codigoBarra) === -1){
            this.datosPropsCopia.codigosBarra.push({
              "codigo_barra_prov": this.datosAgregar.codigoBarra,
              "codigo_ean": null,
              "modificado": 0,
              "prov_articulo_id": 0,
              "articulo_codigo": this.datosPropsCopia.codigo,
              "proveedor_codigo": this.datosAgregar.proveedor,
              "proveedor_nombre": (this.datos.proveedores.find(elem => elem.codigo === this.datosAgregar.proveedor)).nombre,
              "prov_articulo_descripcion": this.datosAgregar.descripcion,
            });
          }else{//si existe ese proveedor y ese código, muestra mensaje
            this.$store.dispatch('show_snackbar', {
              text: 'No es posible agregar el mismo código de barra a un mismo proveedor.',
              color: 'error'
            })
          }
        }
        this.datosAgregar = {
          proveedor: null,
          codigoBarra: null,
          descripcion: null,
        }
        this.$refs.formCodigosBarra.resetValidation();
      }
    },
    eliminarCodigoBarra(item){
      const index = this.datosPropsCopia.codigosBarra.indexOf(item)
      this.datosPropsCopia.codigosBarra.splice(index,1)
    },
    detectarCambios(item){
      if(item.prov_articulo_id != 0){
        const index1 = this.datosPropsCopia.codigosBarra.map(elem => elem.prov_articulo_id).indexOf(item.prov_articulo_id)
        const index2 = this.datos.codigosBarra.map(elem => elem.prov_articulo_id).indexOf(item.prov_articulo_id)

        if(this.datos.codigosBarra[index2].codigo_barra_prov != this.datosPropsCopia.codigosBarra[index1].codigo_barra_prov)
          this.datosPropsCopia.codigosBarra[index1].modificado = 1;
        else
          this.datosPropsCopia.codigosBarra[index1].modificado = 0;
      }
    },
    actualizarCopia(){
      //this.datos = JSON.parse(JSON.stringify(this.datosPropsCopia))
    },
    async guardar(){
      const detalleArr = [];
      if(this.datosPropsCopia.codigosBarra.length != 0){
        let ban = true; 
        let i=0;
        while(i < this.datosPropsCopia.codigosBarra.length && ban){
          if(this.datosPropsCopia.codigosBarra[i].prov_articulo_id === 0){ //solo controlo los recién agregados
            if(this.datosPropsCopia.codigosBarra[i].codigo_barra_prov === '' || this.datosPropsCopia.codigosBarra[i].codigo_barra_prov === null){
              ban = false;
            }
          }
          i++;
        }
        if(ban){ //si todos los codigos recién ingresados tienen algo entonces
          this.datosPropsCopia.codigosBarra.forEach(elem =>
            detalleArr.push({
              proveedor_codigo:  elem.proveedor_codigo,//numérico. Obligatorio. Indica el código de proveedor para el cual va a estar asociado el código de barras.
              prov_articulo_id: elem.prov_articulo_id, //numérico. Obligatorio. Indica el ID de la tabla PROVEEDORES_ARTICULOS. En el caso que este campo llegue en 0 el proceso lo tomará como un nuevo registro y será insertado. Si llega con algo distinto de cero se actualizarán los datos.
              codigo_barra_prov: elem.codigo_barra_prov,  //string. Obligatorio. Indica el código de uso interno del proveedor, que puede coincidir con el código ean o no.
              codigo_ean: elem.codigo_ean, //string. Obligatorio. Indica el código ean del artículo.
              modificado: elem.modificado, //numerico, si está modificado o no
              prov_articulo_descripcion: elem.prov_articulo_descripcion //String. Describe al producto
            })
          );
        }else{
          return this.$store.dispatch('show_snackbar', {
            text: 'Existe algún código de barra recién ingresado que está vacío.',
            color: 'orange'
          })            
        }
      }
      
      if(JSON.stringify(this.datosPropsCopia) != JSON.stringify(this.datos)){
        this.$store.state.loading = true
        await this.$store.dispatch('articulos/actualizarCodigosBarra',{
          articulo_codigo: this.datos.codigo,
          detalles: detalleArr
        })
          .then((res) => {
            this.actualizarCopia()
            this.dialog=false;
            this.$swal.fire({icon: 'success', title: 'Códigos de barra modificados correctamente.'})
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error'
            })
          })
        this.$store.state.loading = false
      }else{
        this.editar = false
        this.$store.dispatch('show_snackbar', {
          text: 'No se realizaron cambios. Los datos son los mismos.',
          color: 'orange',
          timeout: 4000
        })
      }
    }
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
  },
  components:{
    BtnConfirmar, SearchDataTableVue
  },
  watch:{
    dialog(val){
      if(val){
        this.datosPropsCopia = JSON.parse(JSON.stringify(this.datos))
      }else{
        this.editar = false
      }
    },
    editar(val){
      if(val){
        this.datosAgregar ={
          proveedor: '',
          codigoBarra: null,
          descripcion: null,
        }
      }else{
        this.datosPropsCopia = JSON.parse(JSON.stringify(this.datos))
      }
    }
  }
}
</script>

<style>

</style>