<template>
  <div>
    <v-dialog
      v-model="dialog"
      width="700"
      :persistent="load">
      <v-card>
        <v-card-title class="">
          Costos para el Artículo: {{datos.codigo}} - {{ datos.nombre }}
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false">
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text v-if="dialog" class="mt-5 mb-0">
          <v-form>
            <v-row>
              <v-col cols="12" md="4" class="py-1">
                Último Costo (sin IVA)
                <text-field-money-vue
                  v-model.trim="datos.preciosCompra.costo_mayor"
                  hide-details
                  outlined
                  dense
                  v-bind:properties="{
                    'prefix': '$',
                    'validate-on-blur': true,
                    'readonly': true,
                    'filled':true
                  }"
                />
              </v-col>
              <v-col cols="12" md="4" class="py-1">
                  Fecha Último Costo
                  <v-text-field
                    v-model="datos.preciosCompra.fecha_mayor"
                    tabindex="1"
                    hide-details
                    outlined
                    dense
                    :readonly="true"
                    :filled="true"
                    validate-on-blur
                    >
                  </v-text-field>
              </v-col>
              <v-col cols="12" md="4" class="py-1">
                  Origen Último Costo
                  <v-text-field
                    v-model="datos.preciosCompra.origen_mayor"
                    tabindex="1"
                    hide-details
                    outlined
                    dense
                    :readonly="true"
                    :filled="true"
                    validate-on-blur
                    >
                  </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4" class="py-1">
                Costo Compra (sin IVA)
                  <text-field-money-vue
                    v-model.trim="datos.preciosCompra.costo_compra"
                    hide-details
                    outlined
                    dense
                    :readonly="true"
                    :filled="true"
                    v-bind:properties="{
                      'prefix': '$',
                      'validate-on-blur': true,
                      'readonly': true,
                      'filled':true
                    }"
                  />
              </v-col>
              <v-col cols="12" md="4" class="py-1">
                Fecha Compra
                  <v-text-field
                    v-model="datos.preciosCompra.fecha_compra"
                    tabindex="1"
                    hide-details
                    outlined
                    dense
                    :readonly="true"
                    :filled="true"
                    validate-on-blur
                    >
                  </v-text-field>
              </v-col>
              <v-col cols="12" sm="4" class="py-1">
                Origen Compra
                  <v-text-field
                    v-model="datos.preciosCompra.origen_compra"
                    tabindex="1"
                    hide-details
                    outlined
                    dense
                    :readonly="true"
                    :filled="true"
                    validate-on-blur
                    >
                  </v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions class="d-flex justify-end pb-4 pt-0">
          <v-btn
            color="secondary"
            :disabled="load"
            @click="dialog = false">
            Volver
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>  
  </div>
</template>

<script>
import TextFieldMoneyVue from '../../components/util/TextFieldMoney.vue'

export default {
  name:'ModalPreciosCompra',
  data(){
    return{
      load: false,
    }
  },
  created(){

  },
  props:{
    value: Boolean,
    datos:{
      type: Object,
      default:{
        preciosCompra: {},
        codigo: null,
        nombre: ''
      }
    }
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },/*
    datito:{
      get () {
        return this.datos
      },
      set (d) {
        this.$emit('update:datos', d)
      }
    }*/
  },
  components:{
    TextFieldMoneyVue
  }
}
</script>

<style>

</style>