<template>
  <div>
    <v-row class="d-flex justify-center mt-0">
      <v-col cols="12">
        <v-expansion-panels v-model="panel">
          <v-expansion-panel readonly>
            <v-expansion-panel-header class="py-0 px-4 text-h5">
              <div>
                <v-icon class="mb-1" left>fas fa-filter</v-icon>
                Filtro
              </div>
              <template v-slot:actions>
                <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                  <v-icon>fas fa-chevron-down</v-icon>
                </v-btn>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pt-1">
              <v-form @submit.prevent="buscar()">
                <v-row>
                  <v-col cols="12" sm="6" md="2" class="pt-1">
                    Empresa
                    <v-autocomplete
                      v-model="filtro.empresa"
                      :items="empresas.filter(emp => emp.stock_disponible == 1)"
                      item-text="nombre_corto" 
                      item-value="id"
                      tabindex="1"
                      hide-details
                      autofocus
                      outlined
                      dense clearable>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="3" class="pt-1">
                    Sucursal
                    <v-autocomplete
                      v-model="filtro.sucursal"
                      item-text="nombre"
                      item-value="id"
                      tabindex="1"
                      :items="sucursales"
                      hide-details
                      outlined
                      dense
                      clearable 
                      @change="getDepositos()"></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="3" class="pt-1">
                    Tipo Depósito
                    <v-autocomplete
                      v-model="filtro.tipodeposito"
                      item-text="tipo_dep_nombre"
                      item-value="tipo_dep_codigo"
                      tabindex="1"
                      :items="tiposDepositos"
                      hide-details
                      outlined
                      dense
                      multiple
                      deletable-chips
                      chip
                      small-chips
                      @blur="getDepositos()"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" class="pt-1">
                    Depósito
                    <v-autocomplete
                      v-model="filtro.deposito"
                      item-text="deposito_nombre"
                      item-value="deposito_codigo"
                      tabindex="1"
                      :items="depositos"
                      hide-details
                      outlined
                      dense
                      multiple
                      deletable-chips
                      chip
                      small-chips
                    ></v-autocomplete>
                  </v-col>
                <!-- </v-row>
                <v-row > -->
                  <v-col cols="12" sm="6" md="3" class="pt-1">
                    Rubro
                    <v-autocomplete
                      v-model="filtro.rubro"
                      item-text="nombre"
                      item-value="codigo"
                      tabindex="1"
                      :items="rubros"
                      hide-details
                      outlined
                      dense clearable
                      @change="getCategorias()"></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="3" class="pt-1">
                    Categoría
                    <v-autocomplete
                      v-model="filtro.categoria"
                      item-text="nombre"
                      item-value="codigo"
                      tabindex="1"
                      :items="catSelect"
                      hide-details
                      outlined
                      dense clearable
                      ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="2" class="pt-1">
                    Marca
                    <v-autocomplete
                      v-model="filtro.marca"
                      item-text="nombre"
                      item-value="codigo"
                      tabindex="1"
                      :items="marcas"
                      hide-details
                      outlined
                      dense clearable
                      @change="getCategorias()"></v-autocomplete>
                  </v-col>
                  <v-col  cols="12" sm="6" md="2" class="py-1">
                    Original
                    <v-autocomplete
                      v-model="filtro.tipo"
                      item-text="tipo_nombre"
                      item-value="tipo_codigo"
                      tabindex="1"
                      :items="tipos"
                      hide-details
                      clearable
                      outlined
                      dense
                      >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="2" class="pt-1">
                    Artículo código
                    <v-text-field
                      v-model="filtro.articuloCodigo"
                      type="number"
                      tabindex="1"
                      hide-details
                      outlined
                      dense
                      clearable
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="2" class="pt-1">
                    Código Barra
                    <v-text-field
                      v-model="filtro.codigoBarra"
                      tabindex="1"
                      hide-details
                      outlined
                      dense
                      clearable
                    ></v-text-field>
                  </v-col>
                <!-- </v-row>
                <v-row> -->
                  <v-col cols="12" sm="6" md="3" class="pt-1">
                    Serie
                    <v-text-field
                      v-model="filtro.serie"
                      type="number"
                      tabindex="1"
                      hide-details
                      outlined
                      dense
                      clearable
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="3" class="pt-1">
                    Lista Precio
                    <v-autocomplete
                      v-model="filtro.listaPrecio"
                      item-text="sucursal_nombre"
                      item-value="sucursal_codigo"
                      tabindex="1"
                      :items="listasPrecios"
                      hide-details
                      outlined
                      dense clearable
                      ></v-autocomplete>
                  </v-col>
                  <v-col cols="6" sm="6" md="2" class="d-flex justify-center align-center py-1">
                    <v-switch
                      v-model="filtro.articuloEstado"
                      label="Inactivo"
                      class="mb-md-2"
                      tabindex="1"
                      :true-value="1"
                      :false-value="0"
                      hide-details
                      dense
                    ></v-switch>
                  </v-col>
                  <v-col cols="2" class="d-flex justify-end">
                    <BtnFiltro
                      class="pa-0"
                      :loading="load"
                      clase="mt-0"
                      @clear="limpiar()"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <v-col cols="12">
        <v-data-table
          class="cebra elevation-2 mt-2"
          :class="permisoExcel == 1? '' : 'prevent-select'"
          sort-by="deposito_nombre"
          :headers="headers"
          :items="stock"
          :loading="load"
          :search="search"
          :footer-props="{'items-per-page-options':[10, 15, 50, 100]}"
          dense
        >
          <template v-slot:top>
            <v-row class="d-flex justify-end pa-2" no-gutters>
              <v-col cols="6" sm="9" md="10" class="d-flex justify-start align-center">
                <DownloadDataTableVue v-if="permisoExcel == 1"
                  name="Stock Disponible"
                  :data="stock"
                  :headers="headers_excel"
                />
              </v-col>
              <v-col cols="6" sm="3" md="2">
                <SearchDataTableVue
                  v-model="search"
                />
              </v-col>
            </v-row>
          </template>
          <template v-slot:no-data>
            <v-alert
              class="mx-auto mt-4"
              max-width="400"
              type="warning"
              border="left"
              dense
              text
            >
              No hay datos para los filtros seleccionados
            </v-alert>
          </template>
          <template v-slot:[`item.acciones`]="{ item }">
            <v-icon
              color="cyan"
              title="Ver series"
              :disabled="item.solicita_serie != 1"
              small
              @click="open_series(item)"
            >
              {{ item.solicita_serie == 1 ? 'fas fa-th-list' : '' }}
            </v-icon>
            <v-icon
              class="ml-2"
              color="info"
              title="Ver Artículo"
              small
              @click="open_art(item)"
            >
              fas fa-eye
            </v-icon>
            
          </template>
        <template
          v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))"
          v-slot:[`item.${header.value}`]="{ value }"
        >
          {{ header.formatter(value) }}
        </template>
        </v-data-table> 
      </v-col>
    </v-row>
    <!-- COMPONENTES  :datos="datos"-->
    <ModalDetalleSerieVue
      v-model="dialog"
      :datos="datosSeries"
    />

  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { format_money } from '../../util/utils'
import ModalDetalleSerieVue from '../../components/stock/ModalDetalleSerie.vue'
import BtnFiltro from '../../components/util/BtnFiltro'
import DownloadDataTableVue from '../../components/util/DownloadDataTable.vue'
import FechaPicker from '../../components/util/FechaPicker'
import SearchDataTableVue from '../../components/util/SearchDataTable.vue'
import SelectedCounter from '../../components/util/SelectedCounter'
import PDFViewer from '../../util/plantillas/PDFViewer'
import { order_list_by } from '../../util/utils'


export default {
  name: 'StockDisponible',
  data(){
    return{
      panel: 0,
      filtro: {
        empresa: null,
        sucursal: null,
        tipodeposito: [],
        deposito: [],
        rubro: null,
        categoria: null,
        articuloEstado: null,
        articuloCodigo: null,
        codigoBarra: null,
        serie: null, //imei
        listaPrecio: null,
        marca: null,
        tipo: null, 
      },
      catSelect: [],
      //initStockDisponible
      sucursales: [],
      listasPrecios:[],
      tiposDepositos:[],
      permiso: 0,
      permisoExcel: 0,
      //getStockDisponible
      stock:[],
      //getStockDepositos
      depositos:[],
      //getStockSeries
      series: [],
      load: false,
      search: '',
      headers: [
        { text: 'Local', value: 'deposito_nombre'},
        { text: 'Articulo', value: 'articulo_codigo' },
        { text: 'Nombre', value: 'articulo_nombre' },
        { text: 'Marca', value: 'marca_nombre' },
        { text: 'Rubro', value: 'rubro' },
        { text: 'Categ.', value: 'categoria' },
        { text: 'Cant.', value: 'cantidad' },
        { text: 'Precio', value: 'precio_unitario', align: 'end', formatter: format_money },
        { text: 'Lista', value: 'lista_nombre' },
        { text: 'Recom. Vta.', value: 'precio_general', align: 'end', formatter: format_money },
        { text: 'Acciones',value: 'acciones', align: 'center', sortable: false, filterable: false}
      ],
      headers_excel:{
        'Local':        'deposito_nombre',
        'Articulo':     'articulo_codigo',
        'Nombre':       'articulo_nombre',
        'Marca':        'marca_nombre',
        'Rubro':        'rubro',
        'Categoría':    'categoria',
        'Cant.':        'cantidad',
        'Precio':       'precio_unitario',
        'Lista':        'lista_nombre',
        'Recom. Vta.':  'precio_general',
      },
      dialog: false,
      datosSeries: {},
      tipos: [
        {tipo_codigo: 1, tipo_nombre: 'SI'},
        {tipo_codigo: 0, tipo_nombre: 'NO'},
        {tipo_codigo: null, tipo_nombre: 'TODOS'},
      ]
    }
  },
  async created(){
    // si tiene solo 1 empresa la selecciono
    if (this.empresas.filter(emp => emp.stock_disponible == 1).length == 1) {
      this.filtro.empresa = this.empresas.filter(emp => emp.stock_disponible == 1)[0].id
    }
    await this.iniciar();
    // si tiene solo 1 sucursal la selecciono
    if (this.sucursales.length == 1) {
      this.filtro.sucursal = this.sucursales[0].id
    }
    //Marca
    this.$store.dispatch('marcas/get_marcas_habilitadas');
    //Rubro
    this.$store.dispatch('rubros/get_rubros_habilitados');
    //Categorias 
    this.$store.dispatch('categorias/get_categorias_habilitadas');
  },
  computed:{
    ...mapState(['empresas']),
    ...mapState('marcas',['marcas']),
    ...mapState('rubros',['rubros']),
    ...mapState('categorias',['categorias']),
  },
  components: {
    BtnFiltro,
    FechaPicker,
    SelectedCounter,
    PDFViewer,
    DownloadDataTableVue,
    SearchDataTableVue,
    ModalDetalleSerieVue
  },
  methods:{
    ...mapActions('stock',['getStockSeriesDisponibles']),
    async iniciar(){
      this.$store.state.loading = true
      await this.$store.dispatch('stock/initStockDisponible')
        .then((res) => {
          this.listasPrecios = res.listas;
          this.tiposDepositos = res.tipos_depositos; order_list_by(this.tiposDepositos,'tipo_dep_nombre')
          this.permiso = res.permiso;
          this.permisoExcel = res.permiso_descarga_Excel;
          this.sucursales = res.sucursales;
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    async buscar(){
      this.search = '';
      this.stock = [];
      if(!this.filtro.empresa){
        return this.$store.dispatch('show_snackbar', {text: 'Seleccione una empresa',color: 'orange'})
      }

      const aux = this.filtro.deposito.map(elem => ({"deposito_codigo": elem}))

      const aux1 = this.filtro.tipodeposito.map(elem => ({"tipo_dep_codigo": elem }))

      this.load = true;
      await this.$store.dispatch('stock/getStockDisponible',{
        "sucursal_codigo":  this.filtro.sucursal,
        "depositos":        aux,
        "rubro_codigo":     this.filtro.rubro,
        "categoria_codigo": this.filtro.categoria,
        "articulo_codigo":  this.filtro.articuloCodigo,
        "codigo_barra":     this.filtro.codigoBarra,
        "imei":             this.filtro.serie,
        "articulo_estado":  this.filtro.articuloEstado,
        "empresa_codigo":   this.filtro.empresa,
        "lista_precio":     this.filtro.listaPrecio,
        "tipo_deposito":    aux1,
        "marca_id":         this.filtro.marca,
        "tipo":             this.filtro.tipo
      })
      .then((res) => {
        this.stock = res.stock;
      })
      .catch(error => {
        this.$store.dispatch('show_snackbar', { text: error.message, color: 'error' })
      })

      this.load = false;
    },
    limpiar(){
      this.filtro = {
        empresa: null,
        sucursal: null,
        tipodeposito: [],
        deposito: [],
        rubro: null,
        categoria: null,
        articuloEstado: null,
        articuloCodigo: null,
        codigoBarra: null,
        serie: null,
        listaPrecio: null,
        marca: null,
        tipo: null,
      }
      this.stock= [];
      this.search = '';
      this.catSelect = [];
    },
    async getDepositos(){
      if(this.filtro.sucursal || this.filtro.tipodeposito.length > 0){
        this.filtro.deposito = [];
        const aux = [];
        this.filtro.tipodeposito.forEach(elem => aux.push({"tipo_dep_codigo": elem}))

        this.$store.state.loading = true;
        await this.$store.dispatch('stock/getStockDepositos',{
          sucursal_codigo: this.filtro.sucursal ? this.filtro.sucursal : 0,
          tipo_deposito: aux
        })
        .then((res) => {
          this.depositos = res.depositos;
          order_list_by(this.depositos,'deposito_nombre')
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
        this.$store.state.loading = false;
      }else{
        this.filtro.tipodeposito = [];
        this.depositos = [];
        this.filtro.deposito = []
      }
    },
    getCategorias(){
      if(this.filtro.rubro !== null){
        this.catSelect = this.categorias.filter(elem => elem.rubro_codigo === this.filtro.rubro);
      }else{
        this.filtro.categoria = null;
        this.catSelect = [];
      }
    },
    async open_series(item){
      this.$store.state.loading = true;
      await this.$store.dispatch('stock/getStockSeriesDisponibles',{
        deposito: item.deposito_codigo,
        articulo: item.articulo_codigo
      })
      .then(res => {
        this.datosSeries = {
          nombre: item.articulo_nombre,
          art_series: res.series,
          cantidad: item.cantidad
        }
      })
      .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })

      this.$store.state.loading = false;
      this.dialog = true;
    },
    open_art(item){
      let path = `/articulo/${item.articulo_codigo}`
      const routeData = this.$router.resolve({ path: path })
      window.open(routeData.href, '_blank')
    }
  }
}
</script>

<style scoped>
  .prevent-select {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }
</style>