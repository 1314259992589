var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-dialog',{attrs:{"width":"700","persistent":_vm.load},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{},[_vm._v(" Costos para el Artículo: "+_vm._s(_vm.datos.codigo)+" - "+_vm._s(_vm.datos.nombre)+" "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("fas fa-times")])],1)],1),_c('v-divider'),(_vm.dialog)?_c('v-card-text',{staticClass:"mt-5 mb-0"},[_c('v-form',[_c('v-row',[_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","md":"4"}},[_vm._v(" Último Costo (sin IVA) "),_c('text-field-money-vue',{attrs:{"hide-details":"","outlined":"","dense":"","properties":{
                  'prefix': '$',
                  'validate-on-blur': true,
                  'readonly': true,
                  'filled':true
                }},model:{value:(_vm.datos.preciosCompra.costo_mayor),callback:function ($$v) {_vm.$set(_vm.datos.preciosCompra, "costo_mayor", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"datos.preciosCompra.costo_mayor"}})],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","md":"4"}},[_vm._v(" Fecha Último Costo "),_c('v-text-field',{attrs:{"tabindex":"1","hide-details":"","outlined":"","dense":"","readonly":true,"filled":true,"validate-on-blur":""},model:{value:(_vm.datos.preciosCompra.fecha_mayor),callback:function ($$v) {_vm.$set(_vm.datos.preciosCompra, "fecha_mayor", $$v)},expression:"datos.preciosCompra.fecha_mayor"}})],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","md":"4"}},[_vm._v(" Origen Último Costo "),_c('v-text-field',{attrs:{"tabindex":"1","hide-details":"","outlined":"","dense":"","readonly":true,"filled":true,"validate-on-blur":""},model:{value:(_vm.datos.preciosCompra.origen_mayor),callback:function ($$v) {_vm.$set(_vm.datos.preciosCompra, "origen_mayor", $$v)},expression:"datos.preciosCompra.origen_mayor"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","md":"4"}},[_vm._v(" Costo Compra (sin IVA) "),_c('text-field-money-vue',{attrs:{"hide-details":"","outlined":"","dense":"","readonly":true,"filled":true,"properties":{
                    'prefix': '$',
                    'validate-on-blur': true,
                    'readonly': true,
                    'filled':true
                  }},model:{value:(_vm.datos.preciosCompra.costo_compra),callback:function ($$v) {_vm.$set(_vm.datos.preciosCompra, "costo_compra", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"datos.preciosCompra.costo_compra"}})],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","md":"4"}},[_vm._v(" Fecha Compra "),_c('v-text-field',{attrs:{"tabindex":"1","hide-details":"","outlined":"","dense":"","readonly":true,"filled":true,"validate-on-blur":""},model:{value:(_vm.datos.preciosCompra.fecha_compra),callback:function ($$v) {_vm.$set(_vm.datos.preciosCompra, "fecha_compra", $$v)},expression:"datos.preciosCompra.fecha_compra"}})],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","sm":"4"}},[_vm._v(" Origen Compra "),_c('v-text-field',{attrs:{"tabindex":"1","hide-details":"","outlined":"","dense":"","readonly":true,"filled":true,"validate-on-blur":""},model:{value:(_vm.datos.preciosCompra.origen_compra),callback:function ($$v) {_vm.$set(_vm.datos.preciosCompra, "origen_compra", $$v)},expression:"datos.preciosCompra.origen_compra"}})],1)],1)],1)],1):_vm._e(),_c('v-card-actions',{staticClass:"d-flex justify-end pb-4 pt-0"},[_c('v-btn',{attrs:{"color":"secondary","disabled":_vm.load},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Volver ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }