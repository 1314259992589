<template>
<div>
  <!-- DETALLE DE ARTICULO -->
  <v-row class="d-flex justify-center mt-0 mx-md-0 mx-n1 mb-0">
    <v-col cols="12" class="d-flex justify-end align-center pt-0 px-1">
      <div
        v-if="existe"
        v-text="nuevo ? 'Alta de artículo' : `Articulo: ${id} - ${datosFiltradosCopia.nombre}`"
        style="font-size: 20px"
      ></div>
      <v-spacer></v-spacer>
      <v-expand-x-transition>
        <v-switch
          v-if="!nuevo && existe && permisoEditar == 1"
          v-model="editar"
          label="Editar"
          class="d-flex align-center py-0 my-0"
          hide-details
          dense
        ></v-switch><!--&& articulo_copy.inhabilitado != 1-->
      </v-expand-x-transition>
    </v-col>
    <v-card style="width: 100%;">
      <v-col cols="12">
        <v-form ref="form">
          <v-row class="pt-4">
            <v-col cols="6" sm="3" md="3" xl="2" class="py-0" v-if="!nuevo">
              <v-text-field
                v-model.trim="datosFiltrados.codigoArticulo"
                label="Codigo"
                :readonly="true"
                :filled="true"
                validate-on-blur
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="3" md="3" xl="2" class="py-0">
              <v-text-field
                v-model.trim="datosFiltrados.fechaCreacion"
                label="Fecha"
                :rules="editar ? [rules.required] : []"
                :readonly="true"
                :filled="true"
                validate-on-blur
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="3" md="3" xl="2" class="py-0">
              <v-text-field
                v-model.trim="datosFiltrados.usuario"
                label="Usuario Alta"
                :rules="editar ? [rules.required] : []"
                :readonly="true"
                :filled="true"
                validate-on-blur
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="3" md="3" xl="2" class="py-0 d-flex justify-center">
              <v-btn
                v-if="permisoCostos === 1"
                class="mx-2"
                fab
                dark
                small
                color="primary"
                @click="open_preciosCompra()"
                :disabled="id === undefined">
                <v-icon
                  small
                  title="Precios de compra"
                  >fas fa-dollar-sign</v-icon>
              </v-btn>
              <v-btn 
                class="mx-2"
                fab
                dark
                small
                color="success"
                @click="open_codigosBarras()"
                :disabled="id === undefined">
                <v-icon
                  small
                  title="Códigos de barras"
                  >fas fa-barcode</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="8" sm="4" md="6" class="py-0"> 
              <v-text-field
                v-model.trim="datosFiltrados.nombre"
                label="Nombre"
                :readonly="!editar"
                :rules="editar ? [rules.required] : []"
                :filled="!editar"
                validate-on-blur
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" sm="2" md="2" class="py-0">
              <v-autocomplete
                v-model="datosFiltrados.tasaIva"
                label="Tasa de Iva"
                item-text="gravamen_nombre"
                item-value="gravamen_codigo"
                :items="gravamenes"
                :rules="editar ? [rules.required] : []"
                :readonly="!editar"
                :filled="!editar"
                validate-on-blur
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="6" sm="3" md="2" class="py-0">
              <v-autocomplete
                v-model="datosFiltrados.estado"
                label="Estado"
                item-text="estado_nombre"
                item-value="estado_codigo"
                :items="estados"
                :rules="editar ? [rules.required_zero] : []"
                :readonly="!editar"
                :filled="!editar"
                validate-on-blur
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="6" sm="3" md="2" class="py-0">
              <v-autocomplete
                v-model="datosFiltrados.perfil"
                label="Perfil"
                item-text="perfil_nombre"
                item-value="perfil_codigo"
                :items="perfiles"
                :rules="editar ? [rules.required] : []"
                :readonly="!editar"
                :filled="!editar"
                validate-on-blur
                outlined
                dense
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="8" sm="3" md="3" class="py-0">
              <v-autocomplete
                v-model="datosFiltrados.marca"
                label="Marca"
                item-text="nombre"
                item-value="codigo"
                :items="marcas"
                :rules="editar ? [rules.required] : []"
                :readonly="!editar"
                :filled="!editar"
                validate-on-blur
                outlined
                dense
                @change="getModelos()"
              >
                <template v-slot:append-outer v-if="editar">
                  <v-icon
                    class="mt-1"
                    color="success"
                    title="Nueva Marca"
                    small
                    @click="nuevaMarca()"
                  >
                    fas fa-plus-circle
                  </v-icon>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="4" sm="3" md="3" class="py-0">
              <v-autocomplete
                v-model="datosFiltrados.modelo"
                label="Modelo"
                item-text="modelo_nombre"
                item-value="modelo_codigo"
                :items="modeloSelect"
                :rules="editar ? [rules.required] : []"
                :readonly="!editar"
                :filled="!editar"
                validate-on-blur
                outlined
                dense
              >
                <template v-slot:append-outer v-if="editar">
                  <v-icon
                    class="mt-1"
                    color="success"
                    title="Nuevo Modelo"
                    small
                    @click="nuevoModelo()"
                  >
                    fas fa-plus-circle
                  </v-icon>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="6" sm="3" md="3" class="py-0">
              <v-autocomplete
                v-model="datosFiltrados.rubro"
                label="Rubro"
                item-text="nombre"
                item-value="codigo"
                :items="rubros"
                :rules="editar ? [rules.required] : []"
                :readonly="!editar"
                :filled="!editar"
                validate-on-blur
                outlined
                dense
                @change="getCategorias()"
              >
                <template v-slot:append-outer v-if="editar">
                  <v-icon
                    class="mt-1"
                    color="success"
                    title="Nuevo Rubro"
                    small
                    @click="nuevoRubro()"
                  >
                    fas fa-plus-circle
                  </v-icon>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="6" sm="3" md="3" class="py-0">
              <v-autocomplete
                v-model="datosFiltrados.categoria"
                label="Categoria"
                item-text="nombre"
                item-value="codigo"
                :items="catSelect"
                :rules="editar ? [rules.required] : []"
                :readonly="!editar"
                :filled="!editar"
                validate-on-blur
                outlined
                dense
              >
                <template v-slot:append-outer v-if="editar">
                  <v-icon
                    class="mt-1"
                    color="success"
                    title="Nueva Categoria"
                    small
                    @click="nuevaCategoria()"
                  >
                    fas fa-plus-circle
                  </v-icon>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" sm="3" md="3" class="py-0">
              <v-autocomplete
                v-model="datosFiltrados.linea"
                label="Linea"
                item-text="linea_nombre"
                item-value="linea_codigo"
                :items="lineas"
                :readonly="!editar"
                :filled="!editar"
                :clearable="editar"
                validate-on-blur
                outlined
                dense
              >
                <template v-slot:append-outer v-if="editar">
                  <v-icon
                    class="mt-1"
                    color="success"
                    title="Nueva Línea"
                    small
                    @click="nuevaLinea()"
                  >
                    fas fa-plus-circle
                  </v-icon>
                </template>
              </v-autocomplete>
            </v-col>
            <!-- <v-col cols="6" sm="3" md="3" class="py-0">
              <v-autocomplete
                v-model="datosFiltrados.unidadMedida"
                label="Unidad de Medida"
                item-text="unidad_nombre"
                item-value="unidad_codigo"
                :items="unidadesMedidas"
                :rules="editar ? [rules.required] : []"
                :readonly="!editar"
                :filled="!editar"
                validate-on-blur
                outlined
                dense
              ></v-autocomplete>
            </v-col> -->
            <v-col cols="6" sm="3" md="3" class="py-0">
              <v-autocomplete
                v-model="datosFiltrados.tamanio"
                label="Tamaño"
                item-text="tamanio_nombre"
                item-value="tamanio_codigo"
                :items="tamanios"
                :readonly="!editar"
                :filled="!editar"
                validate-on-blur
                outlined
                dense
              >
                <template v-slot:append-outer v-if="editar">
                  <v-icon
                    class="mt-1"
                    color="success"
                    title="Nuevo Tamaño"
                    small
                    @click="nuevoTamanio()"
                  >
                    fas fa-plus-circle
                  </v-icon>
                </template>
              </v-autocomplete>
            </v-col>
            <!-- <v-col cols="6" sm="3" md="3" class="py-0">
              <v-autocomplete
                v-model="datosFiltrados.color"
                label="Color"
                item-text="color_nombre"
                item-value="color_codigo"
                :items="colores"
                :readonly="!editar"
                :filled="!editar"
                :clearable="editar"
                validate-on-blur
                outlined
                dense
              ></v-autocomplete>
            </v-col> -->
            <v-col cols="12" sm="5" md="5" class="py-0">
              <v-checkbox
                v-model="datosFiltrados.prodOriginal"
                label="Original"
                class="pb-2"
                :true-value="1"
                :false-value="0"
                hide-details
                dense
                :disabled="!editar"
              ></v-checkbox>
            </v-col>
          </v-row>
          <!-- agregado el 09-04-2024 por MMURILLO, indicamos si el artículo calcula precio de venta automáticamente -->
          <v-row>
            <v-col cols="12" sm="5" md="5" class="py-0">
              <v-checkbox
                v-model="datosFiltrados.actualizaPrecioOCDir"
                label="No Actualiza Precio Vta. x Ord. Comp. Directa"
                class="pb-2"
                :true-value="1"
                :false-value="0"
                hide-details
                dense
                :disabled="!editar"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" sm="5" md="5" class="py-0">
              <v-checkbox
                v-model="datosFiltrados.actualizaPrecioOCInd"
                label="No Actualiza Precio Vta. x Ord. Comp. Indirecta"
                class="pb-2"
                :true-value="1"
                :false-value="0"
                hide-details
                dense
                :disabled="!editar"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-card>
  </v-row>

  <!-- PRECIOS POR LISTA -->
  <v-row v-if="editar" class="d-flex justify-center mt-0 ">
    <v-col cols="12">
      <v-expansion-panels v-model="panel">
        <v-expansion-panel readonly>
          <v-expansion-panel-header class="py-0 px-4 text-h5">
            <div style="font-size: 20px">
              Precios por Lista
              <v-btn icon color="info">
                <v-icon 
                  small
                  @click="open_preciosLista()">fas fa-search</v-icon>
              </v-btn>
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                <v-icon>fas fa-chevron-down</v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-1">
            <v-form @submit.prevent="agregarNvoPreciosLista" ref="formPrecioLista" class="pt-2">
              <v-row>
                <v-col cols="11">
                  <v-row>
                    <v-col cols="5" sm="6" md="6" lg="2">
                      <v-text-field
                        v-model.trim="datosFormPrecioLista.codigoLista"
                        label="Cód. Lista"
                        type="number"
                        :rules="editar ? [rules.required] : []"
                        validate-on-blur
                        outlined
                        dense
                        @change="getNomTipLista()"
                      ></v-text-field>                 
                    </v-col>
                    <v-col cols="4" sm="3" md="4">
                      <v-text-field
                        v-model.trim="datosFormPrecioLista.nombre"
                        label="Nombre Lista"
                        :readonly="true"
                        :filled="true"
                        validate-on-blur
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="3">
                      <v-text-field
                        v-model.trim="datosFormPrecioLista.tipoNombre"
                        label="Tipo Lista"
                        :readonly="true"
                        :filled="true"
                        validate-on-blur
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="3">
                      <text-field-money-vue
                        v-model.trim="datosFormPrecioLista.precio"
                        label="Precio venta"
                        v-bind:properties="{
                          'prefix': '$',
                          'validate-on-blur': true
                        }"
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="1">
                  <v-btn
                    color="primary"
                    small
                    fab
                    class="mt-n1"
                    title="Agregar Nuevo"
                    type="submit">
                    <v-icon>
                      fas fa-check
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
            <v-data-table      
              class="cebra elevation-2 mt-2"
              :search="search"
              :headers="headers"
              :items="precios"
              :loading="load"
              :items-per-page="-1"
              hide-default-footer
              dense
              >
              <template v-if="editar" v-slot:[`item.precio`]="{ item }">
                <text-field-money-vue
                  v-model="item.precio"
                  class="d-inline-flex"
                  v-bind:properties="{
                    'prefix': '$',
                    'hide-details': true
                  }"
                />
              </template>
              <template v-slot:[`item.accion`]="{item}">
                <v-btn 
                  icon color="error" title="Eliminar"
                  @click="eliminarPreciosLista(item)">
                  <v-icon>
                    fas fa-times-circle
                  </v-icon>
                </v-btn>
              </template>
              <template v-slot:no-data>
                <v-alert
                  class="mx-auto mt-4"
                  max-width="400"
                  type="warning"
                  border="left"
                  dense
                  text
                >
                  No se encontraron datos
                </v-alert>
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
  </v-row>

    <!-- CARACTERISTICAS -->
  <v-row class="d-flex justify-center mt-0 ">
    <v-col cols="12">
      <v-expansion-panels v-model="panel1">
        <v-expansion-panel readonly>
          <v-expansion-panel-header class="py-0 px-4 text-h5">
            <div style="font-size: 20px">
              Características del Artículo
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel1 = panel1 === 1 ? 0 : 1">
                <v-icon>fas fa-chevron-down</v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-1">
            <v-form v-if="editar"
              @submit.prevent="agregarCaracteristica" ref="formCaracteristicasArticulos" class="pt-2">
              <v-row>
                <v-col cols="8">
                  <v-text-field
                    v-model.trim="datosFormCaracteristicas.nombreCarac"
                    label="Característica"
                    :rules="editar ? [rules.required] : []"
                    validate-on-blur
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    v-model.trim="datosFormCaracteristicas.valor"
                    label="Valor"
                    validate-on-blur
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="1">
                  <v-btn
                    color="primary"
                    small
                    fab
                    class="mt-n1"
                    title="Agregar Nueva"
                    type="submit">
                    <v-icon>
                      fas fa-check
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
            <v-data-table      
              class="cebra elevation-2 mt-2"
              :search="search"
              :headers="headers_car"
              :items="caracteristicas"
              :loading="load"
              :items-per-page="-1"
              dense
              item-key="caracteristica"
              :footer-props="{'items-per-page-options':[10, 15, 50, 100]}"
              >
              <template v-slot:top>
                <v-row class="d-flex justify-end pa-2" no-gutters>
                  <v-col cols="6" sm="3" xl="2">
                    <SearchDataTableVue
                      v-model="search"
                    />
                  </v-col>
                </v-row>
              </template>
              <template v-if="editar" v-slot:[`item.valor`]="{item}">
                <v-text-field
                  v-model="item.valor"
                  hide-details
                  dense
                  outlined
                  class="d-inline-flex">
                </v-text-field>
              </template>
              <template v-slot:[`item.accion`]="{item}">
                <v-btn
                  :disabled="!editar"
                  icon color="error" title="Eliminar"
                  @click="eliminarCaracteristica(item)">
                  <v-icon>
                    fas fa-times-circle
                  </v-icon>
                </v-btn>
              </template>
              <template v-slot:no-data>
                <v-alert
                  class="mx-auto mt-4"
                  max-width="400"
                  type="warning"
                  border="left"
                  dense
                  text
                >
                  No se encontraron datos
                </v-alert>
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
  </v-row>

      <!-- E-commerce -->
  <v-row class="d-flex justify-center mt-0" v-if="permisoEcommerce === 1">
    <v-col cols="12">
      <v-expansion-panels v-model="panel2">
        <v-expansion-panel readonly>
          <v-expansion-panel-header class="py-0 px-4 text-h5">
            <v-row class="d-flex align-center" style="font-size: 18px;">
              <v-col cols="12" class="d-flex">
                E-Commerce
                <v-checkbox
                  v-if="editar"
                  v-model="datosFormEcommerce.check"
                  class="mt-0 mr-sm-6 mr-0 pl-3"
                  hide-details
                  dense
                ></v-checkbox>
              </v-col>
            </v-row>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel2 = panel2 === 1 ? 0 : 1">
                <v-icon>fas fa-chevron-down</v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-1">
            <v-form  ref="formEcommerce" class="pt-2">
              <v-row class="pt-4">
                <v-col cols="6" sm="3" md="3" xl="2" class="py-0">
                  <v-text-field
                    v-model.trim="datosFormEcommerce.peso"
                    label="Peso"
                    type="number"
                    placeholder="Peso en gramos"
                    :readonly="!(datosFormEcommerce.check && editar)"
                    :filled="!(datosFormEcommerce.check  && editar)"
                    validate-on-blur
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="3" md="3" xl="2" class="py-0">
                  <v-text-field
                    v-model.trim="datosFormEcommerce.alto"
                    label="Alto"
                    placeholder="Altura en milimétros"
                    type="number"
                    :readonly="!(datosFormEcommerce.check && editar)"
                    :filled="!(datosFormEcommerce.check && editar)"
                    validate-on-blur
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="3" md="3" xl="2" class="py-0">
                  <v-text-field
                    v-model.trim="datosFormEcommerce.ancho"
                    label="Ancho"
                    placeholder="Ancho en milimétros"
                    type="number"
                    :readonly="!(datosFormEcommerce.check && editar)"
                    :filled="!(datosFormEcommerce.check && editar)"
                    validate-on-blur
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="3" md="3" xl="2" class="py-0">
                  <v-text-field
                    v-model.trim="datosFormEcommerce.profundidad"
                    label="Profundidad"
                    placeholder="Profundidad en milimétros"
                    type="number"
                    :readonly="!(datosFormEcommerce.check && editar)"
                    :filled="!(datosFormEcommerce.check && editar)"
                    validate-on-blur
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" class="py-0">
                  <v-text-field
                    v-model.trim="datosFormEcommerce.nombre"
                    label="Nombre"
                    :readonly="!(datosFormEcommerce.check && editar)"
                    :filled="!(datosFormEcommerce.check && editar)"
                    validate-on-blur
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="py-0">
                  <v-autocomplete
                    v-model="datosFormEcommerce.categorias"
                    label="Categorías"
                    item-text="descripcion_larga"
                    item-value="id"
                    :items="categoriasEcommerce"
                    :readonly="!(datosFormEcommerce.check && editar)"
                    :filled="!(datosFormEcommerce.check && editar)"
                    multiple
                    validate-on-blur
                    outlined
                    :deletable-chips="datosFormEcommerce.check && editar"
                    dense
                    chips
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" sm="3" md="3" xl="2" class="py-0">
                  <v-text-field
                    suffix="%"
                    v-model.trim="datosFormEcommerce.descuento"
                    label="Porcentaje de descuento"
                    type="number"
                    :readonly="!(datosFormEcommerce.check && editar)"
                    :filled="!(datosFormEcommerce.check && editar)"
                    validate-on-blur
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="3" md="3" xl="2" class="py-0">
                  <v-checkbox
                    v-model="datosFormEcommerce.prodNuevo"
                    label="Producto Nuevo"
                    class="mt-0 mr-sm-6 mr-0"
                    :true-value="1"
                    :false-value="0"
                    :readonly="!(datosFormEcommerce.check && editar)"
                    :filled="!(datosFormEcommerce.check && editar)"
                    hide-details
                    dense
                  ></v-checkbox>
                </v-col>
                <v-col cols="6" sm="3" md="3" xl="2" class="py-0">
                  <v-checkbox
                    v-model="datosFormEcommerce.ultUnidades"
                    class="mt-0 mr-sm-6 mr-0"
                    label="Últimas unidades"
                    :true-value="1"
                    :false-value="0"
                    :readonly="!(datosFormEcommerce.check && editar)"
                    :filled="!(datosFormEcommerce.check && editar)"
                    hide-details
                    dense
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-textarea
                    v-model="datosFormEcommerce.descripcion"
                    label="Descripción"
                    :readonly="!(datosFormEcommerce.check && editar)"
                    :filled="!(datosFormEcommerce.check && editar)"
                    validate-on-blur
                    outlined
                    dense>
                  </v-textarea>
                </v-col>
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
  </v-row>

  <!-- Componentes -->
    <!-- Ver precios de compra -->
    <ModalPreciosCompraVue
      v-model="dialogP"
      :datos="preciosCompraObj"/>
    <!-- Ver códigos de barra -->
    <ModalCodigosBarraVue
      v-model="dialogC"
      :datos="codigoBarraObj"/>
    <!-- Ver Precios de lista -->
    <ModalPreciosListaVue
      v-model="dialogPL"
      :datos="preciosListaObj"
      @agregarNva="agregarNvoPreciosListaModal"/>
      <!-- Modal Subir Archivos -->
    <ModalUploadImgGralVue 
      :datos="dialogSubirImgs"
      :solo_ver="!permisoEditar"
      @setearModalUpImg="setModalUpImg"
      :p_nombre="dialogSubirImgs.id.toString()"
    />
      <!-- Modal de Nueva Marca -->
    <Marca
      v-model="dialogMarcas"
      :p_nueva="true"
      :p_marca="objMarca"
      @nueva="nuevaMarcaCreada"
    />
    <!-- Modal de Nuevo modelo -->
    <Modelo
      v-model="dialogModelos"
      :p_nuevo="true"
      :p_modelo="objModelo"
      :p_marcas.sync="marcas"
      @nuevo="nuevoModeloCreado"
    />
    <!-- Modal de Nuevo Rubro -->
    <Rubro
      v-model="dialogRubros"
      :p_nuevo="true"
      :p_rubro="objRubro"
      :p_familias="familias"
      @nuevo="nuevoRubroCreado"
    />
    <!-- Modal de Nueva Categoria -->
    <Categoria
      v-model="dialogCategorias"
      :p_nueva="true"
      :p_categoria="objCategoria"
      :p_rubros.sync="rubros"
      @nueva="nuevaCategoriaCreada"
    />
    <!-- Modal de Nuevo Tamaño -->
    <Tamanio
      v-model="dialogTamanios"
      :p_nuevo="true"
      :p_tamanio="objTamanio"
      @nuevo="nuevoTamanioCreado"
    />
    <!-- Modal de Nueva Linea -->
    <Linea
      v-model="dialogLineas"
      :p_nueva="true"
      :p_linea="objLinea"
      :p_rubros="rubros"
      @nueva="nuevaLineaCreada"
    />

  <!-- Botones para abrir las Imágenes del producto -->
  <v-row class="d-flex justify-center" v-if="!nuevo">
    <v-col cols="12"> 
      <v-card >
        <div style="font-size: 20px" class="pa-4">
          Multimedia
        </div>
        <v-row class="d-flex justify-center">
          <v-col cols="12" sm="3" md="3">
            <v-hover v-slot="{ hover }">
              <v-card
                style="cursor: pointer"
                class="pa-3"
                :class="hover ? 'white--text' : ''"
                :elevation="hover ? '10' : ''"
                :color="hover ? 'teal' : ''"
                @click="open_modalImg(null,1)"
              >
                <v-icon
                  :color="hover ? 'white' : 'teal'"
                  left>
                  fas fa-image
                </v-icon>
                Imágenes Grandes
              </v-card>
            </v-hover>
          </v-col>
          <v-col cols="12" sm="3" md="3">
            <v-hover v-slot="{ hover }">
              <v-card
                style="cursor: pointer"
                class="pa-3"
                :class="hover ? 'white--text' : ''"
                :elevation="hover ? '10' : ''"
                :color="hover ? 'teal' : ''"
                @click="open_modalImg(null,2)"
              >
                <v-icon
                  :color="hover ? 'white' : 'teal'"
                  left
                >
                  fas fa-file-image
                </v-icon>
                Imágenes Miniatura
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
  <!-- Botones para guardar o cancelar -->
  <v-row>
    <v-col cols="12" class="d-flex justify-end align-center">
      <v-btn
        class="ml-2 me-3"
        :color="editar ? 'error' : 'secondary'"
        :disabled="load"
        @click="cerrar()"

      >
        {{ editar ? 'Cancelar' : 'Volver' }}
      </v-btn>
      <BtnConfirmarVue
        v-if="editar"
        :loading="load"
        @action="guardar()"
      />
    </v-col>
  </v-row>
</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import ModalPreciosCompraVue from '../../components/stock/ModalPreciosCompra.vue'
import ModalCodigosBarraVue from '../../components/stock/ModalCodigosBarra.vue'
import ModalPreciosListaVue from '../../components/stock/ModalPreciosLista.vue'
import ModalUploadImgGralVue from '../../components/generales/ModalUploadImgGral.vue'
import SearchDataTableVue from '../../components/util/SearchDataTable.vue'
import BtnConfirmarVue from '../../components/util/BtnConfirmar.vue'
import TextFieldMoneyVue from '../../components/util/TextFieldMoney.vue'
import { order_list_by } from '../../util/utils'
import Marca from '../../components/generales/Marca.vue'
import Modelo from '../../components/generales/Modelo.vue'
import Rubro from '../../components/generales/Rubro.vue'
import Categoria from '../../components/generales/Categoria.vue'
import Linea from '../../components/generales/Linea.vue'
import Tamanio from '../../components/generales/Tamanio.vue'

export default {
  name:'UnArticulo',
  data(){
    return{
      id: this.$route.params.id,
      editar: false,
      existe: false,
      nuevo: false,
      //Dialogs
      dialogP: false,
      dialogC: false,
      dialogSubirImgs: {
        activo: false,
        icono: 'fas fa-eye',
        titulo_modal: '',
        tipos: ['.jpg', '.png', '.webp', '.pdf'], // formatos admitidos
        multiple: true,
        max_size: 1, // tamaño del archivo en megas
        ruta: '', // ruta del archivo
        raiz: 0, // indica si vamos a trabajar con la raiz del 10.40 o la de jarvis 2
        tabla: 9, // indica la tabla donde va el log
        id: 0, // generalmente indica un numero identificador para la tabla del log, por ejemplo para facturas de compra seria el id de la factura de compra en la tabla LOG_FACTURAS_COMPRAS en el campo FACTURA_COMPRA_ID
        permiso_borrar: 0, // permiso para borrar archivos
        parametros: {mini: 0}
      },
      dialogPL:         false,
      dialogMarcas:     false,
      objMarca:         {  codigo: null,  nombre: null  },
      dialogModelos:    false,
      objModelo:        {  codigo: null, marca_codigo: null,  nombre: null }, 
      dialogRubros:     false,
      objRubro:         {},
      dialogCategorias: false,
      objCategoria:     { codigo: null, nombre: null, rubro_codigo: null, tipo_fe_codigo: null, solicita_fe_aut: null, no_paga_desc_empl: null, solicita_serie: null, actualiza_precio_may: null },
      dialogTamanios:   false,
      objTamanio:       { codigo: null, abreviacion: null, nombre: null },
      dialogLineas:     false,
      objLinea:         { codigo: null, nombre: null, rubro_codigo: null, categoria_codigo: null },
      lineasNueva:      false,
        //fin dialog
      panel: 0,
      panel1: 0,
      panel2: 0,
      search: '',
      load: false,
      //para los modales 
      preciosCompraObj: {}, //getPreciosCompra
      codigoBarraObj: {}, //getCodigosBarraArt
      preciosListaObj: {}, //open_preciosLista
      //initAMBarticulos
      modelos: [],
      estados: [],
      gravamenes:[],
      perfiles: [],
      tamanios: [],
      colores: [],
      lineas:[],
      unidadesMedidas: [],
      categoriasEcommerce: [],
      permisoEditar: null,
      permisoCostos: null,
      permisoEcommerce: null,
      listasPrecio: [],
      proveedores: [],
        //fin
      familias: [],
      tipos_fe: [],
      //consulta getArticulos
      articulos: [],
      caracteristicas:[],   caracteristicasCopia: [], //copia para el editar en false
      categorias_get:[],
      precios:[],  preciosCopia: [], //copia para cuando el editar sea false
        //fin
      //vectoresAutoselect
      modeloSelect:[],
      catSelect:[],
        //fin
      //Panel principal Datos filtrados, primer panel
      datosFiltradosCopia: {},
      datosFiltrados: {
        codigoArticulo: null,
        fechaCreacion: null,
        usuario: null,
        nombre: '',
        tasaIva: null,
        estado: null,
        perfil: null,
        marca: null,
        modelo: null,
        rubro: null,
        categoria: null, 
        unidadMedida: null,
        tamanio: null, 
        color: null,
        linea: null,
        actualizaPrecioOCDir: 0,
        actualizaPrecioOCInd: 0,
        prodOriginal: 0,
      },
        //fin
      //panel precio por lista
      datosFormPrecioLista:{
        codigoLista: null,
        nombre: null,
        tipoNombre: null,
        tipoCodigo: null,
        precio: null
      },
      preciosItems: [],
      headers:[
        { text: 'Código Lista', value: 'lista_codigo', sortable: false },
        { text: 'Nombre Lista', value: 'lista_nombre', sortable: false },
        { text: 'Tipo Lista', value: 'tipo_lista_nombre', sortable: false },
        { text: 'Precio', value: 'precio', sortable: false },
        { text: 'Acción',value: 'accion', align: 'center', sortable: false, filterable: false}
      ],
        //fin
      //panel Caracteristicas
      datosFormCaracteristicas:{  nombreCarac: null, valor: null },
      headers_car:[
        { text: 'Característica', value: 'caracteristica', sortable: false },
        { text: 'Valor', value: 'valor', sortable: false },
        { text: 'Acción',value: 'accion', align: 'center', sortable: false, filterable: false}
      ],
        //fin
      //Panel E-commerce
      datosFormEcommerce:{
        check: null,
        peso: null, alto: null, ancho: null, profundidad: null, nombre: null, categorias: [], descuento: null,
        prodNuevo: 0, ultUnidades: 0, descripcionCorta: null,descripcion: null
      },
      datosFormEcommerceCopia: {},
        //fin
      //Reglas
      rules:{
        required: value => !!value || 'Campo requerido',
        required_zero: value => {
          if (value == null || value == undefined) {
            return 'Campo requerido'
          } else {
            return true
          }
        }
      }
    }
  },
  created(){
    this.init();
  },
  computed:{
    ...mapState('rubros',['rubros']),
    ...mapState('categorias',['categorias']),
    ...mapState('marcas',['marcas']),
    //...mapActions('marcas',['add_marca'])
  },
  methods:{
    async init(){
      this.$store.state.loading = true;
      //Familias de Rubros
      this.getFlias();
      //Tipos Fe
      this.getTiposFe();
      //Rubro
      this.$store.dispatch('rubros/get_rubros_habilitados');
      //Categorias 
      this.$store.dispatch('categorias/get_categorias_habilitadas');
      //Marca
      this.$store.dispatch('marcas/get_marcas_habilitadas');
      //Datos
      await this.$store.dispatch('articulos/initABMArticulos')
        .then(res => {
          this.estados = res.estados;
          this.gravamenes = res.gravamenes;
          this.perfiles = res.perfiles;
          this.tamanios = res.tamanios;
          this.colores = res.colores;
          this.lineas = res.lineas;
          this.modelos = res.modelos;
          this.unidadesMedidas = res.unidades_medida;
          this.permisoEditar = res.permiso_editar; this.dialogSubirImgs.permiso_borrar = this.permisoEditar;
          this.permisoCostos = res.permiso_costos;
          this.permisoEcommerce = res.permiso_ecommerce;
          this.categoriasEcommerce = res.categorias_ecommerce;
          this.listasPrecio = res.listas_precio;
          this.proveedores = res.proveedores;        order_list_by(this.proveedores, 'nombre')
        })
      this.$store.state.loading = false;

      if(this.id){
        await this.$store.dispatch('articulos/getArticulo',this.id)
        .then(res => {
            this.articulos = res.articulos;
            this.datosFiltrados= {
              codigoArticulo: this.articulos[0].articulo_codigo,
              fechaCreacion: this.articulos[0].fecha_alta,
              usuario: this.articulos[0].usuario_alta,
              nombre: this.articulos[0].articulo_nombre,
              tasaIva: this.articulos[0].gravamen_codigo,
              estado: this.articulos[0].estado_codigo,
              perfil: this.articulos[0].perfil_codigo,
              marca: this.articulos[0].marca_codigo,
              modelo: this.articulos[0].modelo_codigo,
              rubro: this.articulos[0].rubro_codigo,
              categoria: this.articulos[0].categoria_codigo, 
              unidadMedida: this.articulos[0].unidad_codigo,
              tamanio: this.articulos[0].tamanio_codigo, 
              color: this.articulos[0].color_codigo,
              linea: this.articulos[0].linea_codigo,
              actualizaPrecioOCDir: this.articulos[0].actualiza_precio_oc_dir,
              actualizaPrecioOCInd: this.articulos[0].actualiza_precio_oc_ind,
              prodOriginal: this.articulos[0].tipo,
            }
            this.datosFiltradosCopia = JSON.parse(JSON.stringify(this.datosFiltrados));

            this.categorias_get = res.categorias;
            const aux = [];
            if(this.categorias_get.length != 0){
              this.categorias_get.forEach(elem => aux.push(elem.categoria_id ) )
            }

            this.datosFormEcommerce = {
              check: this.articulos[0].es_ecommerce === 1,
              peso: this.articulos[0].peso,   alto: this.articulos[0].altura,   ancho: this.articulos[0].ancho,
              profundidad: this.articulos[0].profundidad,   nombre: this.articulos[0].nombre_ecommerce, 
              descuento: this.articulos[0].descuento,   prodNuevo: this.articulos[0].nuevo_producto, 
              ultUnidades: this.articulos[0].ultimas_unidades, categorias: aux /*Vector de id's */,
              descripcionCorta: '',descripcion: this.articulos[0].descripcion_ecommerce
            };
            this.datosFormEcommerceCopia = JSON.parse(JSON.stringify(this.datosFormEcommerce));
            
            this.getModelos();
            this.getCategorias();
            this.caracteristicas = res.caracteristicas; order_list_by(this.caracteristicas, 'caracteristica')
            this.caracteristicasCopia =  JSON.parse(JSON.stringify(this.caracteristicas)) ;
            this.precios = res.precios;  this.preciosCopia = JSON.parse(JSON.stringify(this.precios)) ;
            this.existe = true;
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error'
            })
          })
      }else{ //si entra el else es nuevo
        this.editar = true;
        this.nuevo = true;
        this.datosFiltrados.fechaCreacion = new Date(Date.now()).toLocaleDateString();
        this.datosFiltrados.usuario = this.$store.state.username;
        this.datosFiltrados.estado = 0; // setea ACTIVO
        this.datosFiltrados.perfil = 2; //setea VIGENTE
        this.datosFiltrados.unidadMedida = 3; //setea en UNIDADES
      }
    },
    async getFlias(){
      //this.$store.state.loading = true
      this.familias = []
      await this.$store.dispatch('rubros/get_familias')
        .then((res) => {
          this.familias = res.data
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error',
          })
        })
      // ordena las familias por nombre desc
      order_list_by(this.familias, 'nombre')
      //this.$store.state.loading = false
    },
    async getTiposFe(){
      this.tipos_fe = []
      await this.$store.dispatch('get_tipos_concepto_fe')
        .then((res) => {
          this.tipos_fe = res.data
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error',
          })
        })
    },
    getModelos(){
      if(this.datosFiltrados.marca !== null){
        this.modeloSelect = this.modelos.filter(elem => elem.marca_codigo === this.datosFiltrados.marca);
        order_list_by(this.modeloSelect, 'modelo_nombre')
      }else{
        this.datosFiltrados.modelo = null;
        this.modeloSelect = []
      }
    },
    getCategorias(){
      if(this.datosFiltrados.rubro !== null){
        this.catSelect = this.categorias.filter(elem => elem.rubro_codigo === this.datosFiltrados.rubro);
      }else{
        this.datosFiltrados.categoria = null;
        this.catSelect = [];
      }
    },
    async open_preciosCompra(){
      if(this.id){
        this.$store.state.loading = true;
        await this.$store.dispatch('articulos/getPreciosCompra',this.id)
        .then(res => {
            //this.preciosCompra = res;
            this.preciosCompraObj = {
              preciosCompra: res,
              nombre: this.datosFiltrados.nombre,
              codigo: this.datosFiltrados.codigoArticulo
            }
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error'
            })
          })
        this.$store.state.loading = false;
        this.dialogP = true;
      }
    },
    async open_codigosBarras(){
      if(this.id){
        this.$store.state.loading = true;
        await this.$store.dispatch('articulos/getCodigosBarraArt',this.id)
        .then(res => {
          //this.preciosCompra = res;
          this.codigoBarraObj = {
            codigosBarra: res.codigos_barra,
            proveedores: this.proveedores,
            //nombre: this.datosFiltrados.nombre,
            codigo: this.datosFiltrados.codigoArticulo,
            permiso: this.permisoEditar
          }
          order_list_by(this.codigoBarraObj.codigosBarra, 'codigo_barra_prov')
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
        this.$store.state.loading = false;
        this.dialogC = true;
      }
    },
    open_preciosLista(){
      this.$store.state.loading = true;
      this.preciosListaObj = {
        //precios: this.precios,
        listasPrecio: this.listasPrecio
      }
      this.$store.state.loading = false;
      this.dialogPL = true;
    },
    setModalUpImg(value){
      this.dialogSubirImgs.activo = value;
    },
    open_modalImg(par, band){
      this.$store.state.loading = true;
      if(band == 1){ //Grandes
        //titulo modal
        this.dialogSubirImgs.titulo_modal = 'Imágenes grandes para el Artículo ' + this.datosFiltrados.codigoArticulo;
        //ruta de carpeta
        this.dialogSubirImgs.ruta = '/acc_imagenes/' + this.datosFiltrados.codigoArticulo;
        //id
        this.dialogSubirImgs.id = this.datosFiltrados.codigoArticulo;
        this.dialogSubirImgs.parametros.mini = 0;
        //Abrir modal
        this.dialogSubirImgs.activo = true
        
      }else{//Miniatura
        //titulo modal
        this.dialogSubirImgs.titulo_modal = 'Imágenes miniatura para el Artículo ' + this.datosFiltrados.codigoArticulo;
        //ruta de carpeta
        this.dialogSubirImgs.ruta = '/acc_imagenes_mini/' + this.datosFiltrados.codigoArticulo;
        //id
        this.dialogSubirImgs.id = this.datosFiltrados.codigoArticulo;
        this.dialogSubirImgs.parametros.mini = 1;
              //Abrir modal
        this.dialogSubirImgs.activo = true
        
      }
      this.$store.state.loading = false;
    },
    
    //Panel Precios por lista
    getNomTipLista(){ //devuelve en los campos nombre y tipo del objeto 'datosFormPrecioLista' los valor correspondientes según el código
      if(this.datosFormPrecioLista.codigoLista){
        const bus = this.datosFormPrecioLista.codigoLista;
        const aux = (this.listasPrecio.filter(elem => elem.lista_codigo == bus))
        if(aux.length !== 0){
          //cargar text field
          const obj = aux[0];
          this.datosFormPrecioLista.nombre = obj.lista_nombre;
          this.datosFormPrecioLista.tipoNombre = obj.tipo_lista_nombre;
          this.datosFormPrecioLista.tipoCodigo = obj.tipo_lista;

        }else{
          // si no existe el códgio modal se abre precioLista
          this.open_preciosLista()
        }
        //si no se ingresa resetea
      }else{
        this.datosFormPrecioLista.nombre = null;
        this.datosFormPrecioLista.tipoNombre = null;
        this.datosFormPrecioLista.tipoCodigo = null;
      }
    },
    async agregarNvoPreciosListaModal(nvo){
      this.datosFormPrecioLista.codigoLista = nvo.lista_codigo;
      this.datosFormPrecioLista.precio = nvo.precio;
      this.getNomTipLista();
      await new Promise(resolve => setTimeout(resolve, 1))
      this.agregarNvoPreciosLista();
      //this.dialogPL = false;
    },
    agregarNvoPreciosLista(){
      if(this.$refs.formPrecioLista.validate()){
        //si no existe, Agrega
        if(this.precios.find(elem => elem.lista_codigo == this.datosFormPrecioLista.codigoLista) === undefined){
          this.precios.push({
            "lista_codigo": this.datosFormPrecioLista.codigoLista,
            "lista_nombre": this.datosFormPrecioLista.nombre,
            "tipo_lista_codigo": this.datosFormPrecioLista.tipoCodigo,
            "tipo_lista_nombre": this.datosFormPrecioLista.tipoNombre,
            "precio": this.datosFormPrecioLista.precio,
          })
        //si existe muestra un mensaje
        }else{
          this.$store.dispatch('show_snackbar', {
            text: 'El precio de lista ya existe.',
            color: 'error'
          }) 
        }
        this.datosFormPrecioLista = {
          codigoLista: null,
          nombre: null,
          tipo: null,
          precio: null
        }
      }
    },
    eliminarPreciosLista(item){
      this.precios = this.precios.filter(elem => elem.lista_codigo != item.lista_codigo)
    },
    //fin

    //Panel de caracteristicas
    agregarCaracteristica(){
      if(this.$refs.formCaracteristicasArticulos.validate()){
        //si NO existe, agregamos
        if(this.caracteristicas.map(elem => elem.caracteristica).indexOf(this.datosFormCaracteristicas.nombreCarac) === -1){
          this.caracteristicas.push({
            "id": 0,
            "caracteristica": this.datosFormCaracteristicas.nombreCarac,
            "valor": this.datosFormCaracteristicas.valor,
            "articulo_codigo": this.datosFiltrados.codigoArticulo,
            "orden": null
          })
          order_list_by(this.caracteristicas, 'caracteristica')
        //si existe muestra cartel
        }else{
          this.$store.dispatch('show_snackbar', {
            text: 'La característica ya existe.',
            color: 'error'
          }) 
        }
        this.datosFormCaracteristicas = { nombreCarac: null, valor: null}
      }
    },
    eliminarCaracteristica(item){
      const index = this.caracteristicas.indexOf(item)
      this.caracteristicas.splice(index,1)
    },
    //fin 
    removeCat(item){
      if(this.datosFormEcommerce.check){
        this.datosFormEcommerce.categorias = this.datosFormEcommerce.categorias.filter(elem => elem != item.id)
      }
    },
    actualizarCopia(){
      this.datosFiltradosCopia = JSON.parse(JSON.stringify(this.datosFiltrados));
      this.caracteristicasCopia = JSON.parse(JSON.stringify(this.caracteristicas)) ;
      this.preciosCopia = JSON.parse(JSON.stringify(this.precios));
      this.datosFormEcommerceCopia = JSON.parse(JSON.stringify(this.datosFormEcommerce));
      this.editar = false;
    },
    cerrar () {
      //window.close()
      this.$router.push({ path: "/index/articulos" });
    },
    async guardar(){
      if(this.$refs.form.validate()){
        let encabezado = {
          //No Nulos
          art_nombre: this.datosFiltrados.nombre, //string. nombre del artículo seleccionado.
          art_tasa_iva: this.datosFiltrados.tasaIva,  //numérico. Código del gravamen seleccionado.
          art_perfil: this.datosFiltrados.perfil, //numérico: Código del perfil seleccionado.
          art_marca:  this.datosFiltrados.marca, //numérico. Código de la marca seleccionada.
          art_modelo: this.datosFiltrados.modelo, //numérico. Código del modelo seleccionado.
          art_modelo_nom: (this.modelos.find(elem => elem.modelo_codigo === this.datosFiltrados.modelo)).modelo_nombre,//string. Nombre del modelo seleccionado.
          art_rubro: this.datosFiltrados.rubro, //numérico: Código del rubro seleccionado.
          art_categoria: this.datosFiltrados.categoria,//numérico. Código de la categoría seleccionada.
          art_estado: this.datosFiltrados.estado, //numérico. Indica el estado del artículo. 0 para indicar que está habilitado o activo y 1 para indicar lo contrario.
          art_para_ec: this.datosFormEcommerce.check ? 1 : 0,  //numérico. Indica si el artículo es para el ecommerce o no. 0 para indicar que no es para ecommerce, 1 para indicar lo contrario.
          //Pueden ser nuelos
          art_tam: this.datosFiltrados.tamanio, //numérico. Código del tamaño seleccionado.
          art_tam_nom: !this.datosFiltrados.tamanio ? null : (this.tamanios.find(elem => elem.tamanio_codigo === this.datosFiltrados.tamanio)).tamanio_nombre,//string. Nombre del tamaño seleccionado.
          art_linea: this.datosFiltrados.linea,  //numérico. Código de la línea seleccionada.
          art_linea_nom: !this.datosFiltrados.linea ? null : (this.lineas.find(elem => elem.linea_codigo === this.datosFiltrados.linea)).linea_nombre,  //string. Nombre de la línea seleccionada.
          art_color: this.datosFiltrados.color, //numérico. Código del color seleccionado.
          art_color_nom: !this.datosFiltrados.color ? null : (this.colores.find(elem => elem.color_codigo === this.datosFiltrados.color)).color_nombre,  //string. Nombre del color seleccionado.
          art_nom_e: this.datosFormEcommerce.nombre,  //string. Nombre del artículo para el ecommerce.
          art_prof: this.datosFormEcommerce.profundidad, //numérico. Indica la profundidad del artículo.
          art_ancho: this.datosFormEcommerce.ancho,  //numérico. Indica el ancho del artículo.
          art_alto: this.datosFormEcommerce.alto, //numérico. Indica el alto del artículo.
          art_peso: this.datosFormEcommerce.peso, //numérico. Indica el peso del artículo.
          art_desc_corta: this.datosFormEcommerce.descripcion , //string. Es una breve descripción del artículo para el ecommerce.
          descuento: this.datosFormEcommerce.descuento ? 0: parseFloat(this.datosFormEcommerce.descuento),  //numérico. Indica el descuento del artículo para el ecommerce.
          nuevo_producto: this.datosFormEcommerce.prodNuevo, //numérico. Indica si es un nuevo producto. 0 para indicar que no es nuevo producto y 1 para el caso contrario.
          ultimas_unidades: this.datosFormEcommerce.ultUnidades, //numérico. Indica si existen pocas unidades del producto. 0 para indicar que no son últimas unidades y 1 para el caso contrario.
          actualiza_precio_oc_dir: this.datosFiltrados.actualizaPrecioOCDir,
          actualiza_precio_oc_ind: this.datosFiltrados.actualizaPrecioOCInd,
          original: this.datosFiltrados.prodOriginal,
        }
        //Valida el array precios
        const preciosArr = [];
        
        if(this.precios.length != 0){
          let ban = true; 
          let i=0;
          while(i < this.precios.length && ban){
            if(parseFloat(this.precios[i].precio.toFixed(2)) <= 0 || this.precios[i].precio === '' || this.precios[i].precio === null){
              ban = false;
            }
            i++;
          }
          if(ban){  
            this.precios.forEach(elem => preciosArr.push({lista_codigo: elem.lista_codigo, precio: elem.precio}))
          }else{
            return this.$store.dispatch('show_snackbar', {
              text: 'Algún precio por lista es menor o igual a 0 o bien se encuentra vacío',
              color: 'orange'
            })            
          }
        }else{
          return this.$store.dispatch('show_snackbar', {
            text: 'Se debe agregar al menos un Precio por lista',
            color: 'orange'
          })
        }
        //Valida y Construye array de caracteristicas
        const caracteristicaArr = [];
        if(this.caracteristicas.length != 0){
          let ban = true; 
          let i=0;
          while(i < this.caracteristicas.length && ban){
            if(this.caracteristicas[i].valor === '' || this.caracteristicas[i].valor === null){
              ban = false;
            }
            i++;
          }
          if(ban){
            this.caracteristicas.forEach(elem => 
              caracteristicaArr.push(
                { 
                  id: elem.id, 
                  campo_caract: elem.caracteristica, 
                  valor_caract: elem.valor 
                }
            ));
          }else{
            return this.$store.dispatch('show_snackbar', {
              text: 'Existe un valor de caracteristica vacío.',
              color: 'orange'
            })            
          }
        }
        //construye array de categorias
        const categoriasArr = [];
        this.datosFormEcommerce.categorias.forEach(elem => 
          categoriasArr.push(
            {
              articulo_codigo: this.datosFiltrados.codigoArticulo,
              categoria_id: elem,
            }
        ));
        
        //si es nuevo
        if(this.nuevo){
          this.$store.state.loading = true
          await this.$store.dispatch('articulos/nuevoArticulo',{
            encabezado: encabezado,
            precios: preciosArr,
            caracteristicas: caracteristicaArr,
            categorias: categoriasArr
          })
            .then(async (res) => {
              this.actualizarCopia()
              await this.$swal.fire({icon: 'success', title: `Artículo ${res.articulo_codigo} agregado correctamente.`, timer: 4000})
              this.cerrar();
            })
            .catch(error => {
              this.$store.dispatch('show_snackbar', {
                text: error.message,
                color: 'error'
              })
            })
          this.$store.state.loading = false
          
        }else{ //si NO es nuevo, ya existe
          //si cambió algún valor
          if(JSON.stringify(this.datosFiltradosCopia) != JSON.stringify(this.datosFiltrados) ||
             JSON.stringify(this.caracteristicasCopia) != JSON.stringify(this.caracteristicas) ||
             JSON.stringify(this.preciosCopia) != JSON.stringify(this.precios) ||
             JSON.stringify(this.datosFormEcommerceCopia) != JSON.stringify(this.datosFormEcommerce)){
              encabezado.articulo_codigo = this.datosFiltrados.codigoArticulo;
              this.$store.state.loading = true
              await this.$store.dispatch('articulos/actualizarArticulo',{
                encabezado: encabezado,
                precios: preciosArr,
                caracteristicas: caracteristicaArr,
                categorias: categoriasArr
              })
                .then(async (res) => {
                  this.actualizarCopia()
                  await this.$swal.fire({icon: 'success', title: 'Datos actualizados correctamente.', timer: 4000})
                })
                .catch(error => {
                  this.$store.dispatch('show_snackbar', {
                    text: error.message,
                    color: 'error'
                  })
                })
              this.$store.state.loading = false
          }else{ //sino
            this.editar = false
            this.$store.dispatch('show_snackbar', {
              text: 'No se realizaron cambios en el Artículo. Los datos nuevos son iguales a los anteriores',
              color: 'orange',
              timeout: 4000
            })
          }
        }
      }else{
        this.$store.dispatch('show_snackbar', {
            text: 'Complete los campos obligatorios',
            color: 'orange'
          })
      }
      
    },
    nuevaMarca(){
      this.dialogMarcas = true
      this.objMarca = {
        codigo: null,
        nombre: null
      }
    },
    nuevaMarcaCreada(item){
      this.$store.dispatch('marcas/add_marca', { codigo: item.codigo, nombre: item.nombre, inhabilitada: 0 })
      this.datosFiltrados.marca = item.codigo;
    },
    nuevoModelo(){
      this.dialogModelos = true;
      this.objModelo = {
        codigo: null, 
        marca_codigo: this.datosFiltrados.marca,  
        nombre: null 
      };
    },
    nuevoModeloCreado(item){
      this.modelos.push({
        marca_codigo:  item.marca_codigo,
        modelo_codigo: item.codigo,
        modelo_nombre: item.nombre
      });
      this.datosFiltrados.marca   = item.marca_codigo;
      this.datosFiltrados.modelo  = item.codigo;
      this.getModelos();
    },
    nuevoRubro(){
      this.dialogRubros = true;
    },
    nuevoRubroCreado(item){
      this.$store.dispatch('rubros/add_rubro',{
        codigo: item.codigo,
        nombre: item.nombre,
        familia_codigo: item.familia_codigo,
        familia_nombre: (this.familias.filter(e => e.codigo == item.familia_codigo))[0].nombre,
        ind_rot_min: item.ind_rot_min,
        ind_rot_max: item.ind_rot_max
      });
      this.datosFiltrados.rubro = item.codigo;
    },
    nuevaCategoria(){
      this.objCategoria.rubro_codigo = this.datosFiltrados.rubro ? this.datosFiltrados.rubro : null;
      this.dialogCategorias = true;
    },
    nuevaCategoriaCreada(item){
      item.rubro_nombre = (this.rubros.filter(e => e.codigo == item.rubro_codigo))[0].nombre;
      item.tipo_fe_nombre = (this.tipos_fe.filter(e=>e.id == item.tipo_fe_codigo))[0].nombre;
      this.$store.dispatch('categorias/add_categoria',item);
      this.datosFiltrados.rubro     = item.rubro_codigo;
      this.datosFiltrados.categoria = item.codigo;
      this.getCategorias();
    },
    nuevoTamanio(){
      this.dialogTamanios = true;
    },
    nuevoTamanioCreado(item){
      this.datosFiltrados.tamanio = item.tamanio_codigo;
      this.tamanios.push(item);
      order_list_by(this.tamanios, 'tamanio_nombre');
    },
    nuevaLinea(){
      this.objLinea.rubro_codigo      = this.datosFiltrados.rubro ? this.datosFiltrados.rubro : null;
      this.objLinea.categoria_codigo  = this.datosFiltrados.categoria ? this.datosFiltrados.categoria : null;
      this.dialogLineas = true;
    },
    nuevaLineaCreada(item){
      this.datosFiltrados.linea     = item.codigo;
      this.datosFiltrados.rubro     = item.rubro_codigo;
      this.datosFiltrados.categoria = item.categoria_codigo;
      this.getCategorias();
      this.lineas.push({linea_codigo: item.codigo, linea_nombre: item.nombre});
      this.dialogLineas = false;
    }
  },
  watch:{
    editar(val){
      if(!val){
        this.datosFiltrados = JSON.parse(JSON.stringify(this.datosFiltradosCopia))
        this.caracteristicas = JSON.parse(JSON.stringify(this.caracteristicasCopia)) ;
        this.precios = JSON.parse(JSON.stringify(this.preciosCopia));
        this.datosFormEcommerce = JSON.parse(JSON.stringify(this.datosFormEcommerceCopia));
      }else{
      }
    }
  },
  components:{
    ModalPreciosCompraVue,
    ModalCodigosBarraVue,
    ModalPreciosListaVue,
    ModalUploadImgGralVue,
    SearchDataTableVue,
    TextFieldMoneyVue,
    BtnConfirmarVue,
    Marca, Modelo, 
    Rubro, Categoria, Linea,
    Tamanio
  }

}
</script>

<style>

</style>